const config = {
  ENVIRONMENT_NAME: 'dev',
  forwoodId: {
    URL: 'https://id.dev.exploration.forwoodsafety.com',
    APP_CLIENT_ID: '3pv2rj3deajq24ffrer5ed942c',
    USER_TOKEN_URL: 'https://6t1vfo4b52.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://g2bx6kndy8.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.9.1',
    HOSTNAME: 'https://admin.dev.exploration.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.dev.exploration.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.dev.exploration.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.dev.exploration.forwoodsafety.com',
    WEBSOCKET: 'wss://qud3gskc58.execute-api.us-west-2.amazonaws.com/dev'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;